import React from 'react';
import { EmptyTable } from '@/notifications/management/EmptyTable';

export const EmptyReportTable = () => (
  <EmptyTable
    testId="reportTableEmpty"
    header="NOTIFICATIONS_MANAGEMENT.REPORT.EMPTY.HEADER"
    body="NOTIFICATIONS_MANAGEMENT.REPORT.EMPTY.BODY"
    linkText="NOTIFICATIONS_MANAGEMENT.REPORT.EMPTY.ACTION"
    linkUrl="https://seeq.atlassian.net/wiki/spaces/KB/pages/1079574556/Scheduling+Organizer+Topic+Documents#Adding-Email-Recipients%5BhardBreak%5D"
  />
);
