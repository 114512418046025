import { CELL_TYPES, TableColumn } from '@/core/Table.atom';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionMonitorOutputV1, sqConditionMonitorsApi, sqItemsApi } from '@/sdk';
import { errorToast, successToast, warnToast } from '@/utilities/toast.utilities';
import { FakeLink } from '@/core/FakeLink';
import {
  CONDITION_MONITORS_TABLE_CANCELLATION_GROUP,
  DEFAULT_FILTERS,
  DEFAULT_SORT,
} from '@/notifications/notification.constants';
import { MoreActions } from '@/notifications/notification.types';
import { MoreActionsButton } from '@/notifications/management/MoreActionsButton';
import { EmptyConditionMonitorTable } from '@/notifications/management/EmptyConditionMonitorTable';
import { BulkTableActionOptions, BulkTableActionsConfig } from '@/notifications/management/BulkTableActions';
import { FetchItemsParams, PaginationTable } from '@/notifications/management/PaginationTable';
import { useReloadTable } from '@/notifications/management/hooks/useReloadTable.hook';
import { sqWorkbenchStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { SortFieldEnum } from '@/sdk/api/ConditionMonitorsApi';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { IdentityOption, SelectIdentity } from '@/core/SelectIdentity.molecule';
import { NotificationsConfigureModal } from '@/notifications/NotificationsConfigureModal.molecule';
import { NotificationEditingContext } from '@/notifications/notifications.constants';
import { ConfirmDeleteNotificationModal } from './ConfirmDeleteNotificationModal';

const updateConditionMonitorStatus = (conditionMonitor?: ConditionMonitorOutputV1, shouldEnable?: boolean) =>
  conditionMonitor
    ? sqItemsApi.setProperty(
        { value: shouldEnable ?? !conditionMonitor.enabled },
        { id: conditionMonitor.id, propertyName: SeeqNames.Properties.Enabled },
      )
    : null;

export const ConditionMonitorTable = () => {
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);
  const { t } = useTranslation();

  const { reloadTable, reloadTableTrigger } = useReloadTable();

  const [identities, setIdentities] = useState<IdentityOption[]>([]);
  const [conditionMonitorIdToEdit, setConditionMonitorIdToEdit] = useState<string>();
  const [deleteConfig, setDeleteConfig] = useState<
    BulkTableActionOptions<ConditionMonitorOutputV1> | ConditionMonitorOutputV1
  >();

  const moreActions: MoreActions<ConditionMonitorOutputV1>[] = [
    {
      href: '#',
      iconClass: _.constant('fa-pencil'),
      translationKey: _.constant('EDIT'),
      action: (item) => setConditionMonitorIdToEdit(item.id),
      display: true,
      testId: 'edit',
    },
    // {
    //   href: '#',
    //   iconClass: (item) => (item?.enabled ? 'fa-close' : 'fa-check'),
    //   translationKey: (item) => (item?.enabled ? 'DISABLE' : 'ENABLE'),
    //   action: (item, onComplete) =>
    //     updateConditionMonitorStatus(item)
    //       ?.then(onComplete)
    //       .then(() =>
    //         successToast({
    //           messageKey: `NOTIFICATIONS_MANAGEMENT.CONDITION_MONITOR.${
    //             item.enabled ? 'DISABLED_SUCCESS' : 'ENABLED_SUCCESS'
    //           }`,
    //           messageParams: { conditionMonitorName: item.name },
    //         }),
    //       ),
    //   display: true,
    //   testId: 'disable/enable',
    // },
    {
      href: '#',
      iconClass: _.constant('fa-trash'),
      translationKey: _.constant('DELETE'),
      action: (item) => setDeleteConfig(item),
      display: true,
      testId: 'delete',
    },
  ];

  const columns: TableColumn<ConditionMonitorOutputV1>[] = [
    {
      accessor: 'id',
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, maxWidth: 40, verticalAlign: 'middle' },
      isResizable: false,
    },
    {
      accessor: 'name',
      filterable: true,
      sortable: true,
      searchProperty: 'nameSearch',
      header: 'NOTIFICATIONS_MANAGEMENT.HEADER.NAME',
      cellStyle: { wordWrap: 'break-word' },
      cellRenderFunction: (item, accessor = 'name') => (
        <FakeLink onClick={() => setConditionMonitorIdToEdit(item.id)}>{item[accessor] ?? ''}</FakeLink>
      ),
    },
    {
      accessor: 'creator.name',
      header: 'NOTIFICATIONS_MANAGEMENT.CONDITION_MONITOR.HEADER.CREATOR',
      cellStyle: { wordWrap: 'break-word', width: 300 },
      headerRenderFunction: currentUser.isAdmin
        ? (column) => (
            <div className="flexRowContainer">
              <div data-testid={`${column!.accessor}_header`} className="flexColumnContainer cursorNotAllowed">
                {t(column!.header!)}
              </div>
              <div className="multiSelectTwoLineScrollOnly">
                <SelectIdentity
                  idForLabel="identityName"
                  onIdentityChange={(identities) => {
                    setIdentities(identities as IdentityOption[]);
                    reloadTable();
                  }}
                  setIdentity={_.noop}
                  identity={identities}
                  startEditable={true}
                  placeholder="USAGE.USER_PLACEHOLDER"
                  multiple={true}
                  prefillIdentity={true}
                />
              </div>
            </div>
          )
        : undefined,
    },
    {
      accessor: 'enabled',
      header: 'STATUS',
      cellType: CELL_TYPES.ENABLED_DISABLED,
      searchProperty: 'enabledFilter',
      filterable: true,
      cellStyle: { width: 100, wordWrap: 'break-word' },
      cellRenderFunction: (item) => t(item.enabled ? 'ENABLED' : 'DISABLED'),
    },
    {
      accessor: 'id',
      cellStyle: { width: 30, maxWidth: 30 },
      cellRenderFunction: (item) => <MoreActionsButton item={item} moreActions={moreActions} loadItems={reloadTable} />,
      isResizable: false,
    },
  ];

  const deleteConditionMonitor = async (item: ConditionMonitorOutputV1) => {
    await sqConditionMonitorsApi.archiveConditionMonitor({ id: item.id });
    reloadTable();
    successToast({
      messageKey: 'NOTIFICATIONS_MANAGEMENT.CONDITION_MONITOR.DELETE_SUCCESS',
      messageParams: { conditionMonitorName: item.name },
    });
  };

  const enableConditionMonitors = async (
    shouldEnable: boolean,
    { selectedIds, items, clearSelectedRows }: BulkTableActionOptions<ConditionMonitorOutputV1>,
  ) => {
    try {
      const itemsToUpdate = _.filter(
        items,
        (item) => _.includes(selectedIds, item.id) && (shouldEnable ? !item.enabled : item.enabled),
      );
      if (itemsToUpdate.length === 0) {
        warnToast({ messageKey: shouldEnable ? 'BULK_ENABLE_WARNING' : 'BULK_DISABLE_WARNING' });
        return;
      }

      await Promise.all(_.map(itemsToUpdate, (item) => updateConditionMonitorStatus(item, shouldEnable)));
      reloadTable();
      successToast({
        messageKey: shouldEnable
          ? 'NOTIFICATIONS_MANAGEMENT.BULK_ENABLED_SUCCESS'
          : 'NOTIFICATIONS_MANAGEMENT.BULK_DISABLED_SUCCESS',
        messageParams: { amount: itemsToUpdate.length },
      });
    } catch (e) {
      errorToast({ httpResponseOrError: e, displayForbidden: true });
    } finally {
      clearSelectedRows();
    }
  };

  const deleteConditionMonitors = async ({
    selectedIds,
    clearSelectedRows,
  }: BulkTableActionOptions<ConditionMonitorOutputV1>) => {
    try {
      await Promise.all(_.map(selectedIds, (id) => sqConditionMonitorsApi.archiveConditionMonitor({ id })));
      reloadTable();
      successToast({
        messageKey: 'NOTIFICATIONS_MANAGEMENT.BULK_DELETE_SUCCESS',
        messageParams: { amount: selectedIds.length },
      });
    } catch (e) {
      errorToast({ httpResponseOrError: e, displayForbidden: true });
    } finally {
      clearSelectedRows();
    }
  };

  const bulkActionsConfig: BulkTableActionsConfig<ConditionMonitorOutputV1>[] = [
    // { id: 'enableSelected', label: 'ENABLE_SELECTED', onClick: (options) => enableConditionMonitors(true, options) },
    { id: 'disableSelected', label: 'DISABLE_SELECTED', onClick: (options) => enableConditionMonitors(false, options) },
    { id: 'deleteSelected', label: 'DELETE_SELECTED', onClick: setDeleteConfig },
  ];

  const getSortProperty = (accessor?: string) => {
    if (accessor === 'name') {
      return SortFieldEnum.Name;
    }

    return SortFieldEnum.CreatedAt;
  };

  const fetchItems = ({ limit, offset, sortAsc, sortProperty, searchParams = {} }: FetchItemsParams) =>
    sqConditionMonitorsApi.getConditionMonitors(
      {
        limit,
        offset,
        sortAsc,
        sortField: getSortProperty(sortProperty),
        creatorIds: currentUser.isAdmin ? _.map(identities, 'id') : [sqWorkbenchStore.currentUser.id],
        ...searchParams,
      },
      { cancellationGroup: CONDITION_MONITORS_TABLE_CANCELLATION_GROUP },
    );

  return (
    <>
      <PaginationTable
        testId="conditionMonitorTable"
        columns={columns}
        itemsAccessor="conditionMonitorItems"
        bulkActionsConfig={bulkActionsConfig}
        emptyPlaceHolder={<EmptyConditionMonitorTable />}
        fetchItems={fetchItems}
        reloadTableTrigger={reloadTableTrigger}
        defaultFilters={DEFAULT_FILTERS}
        defaultSort={DEFAULT_SORT}
        extraFilters={{ creatorIds: currentUser.isAdmin ? _.map(identities, 'id') : [] }}
      />

      {conditionMonitorIdToEdit && (
        <NotificationsConfigureModal
          context={NotificationEditingContext.NotificationManagement}
          conditionMonitorId={conditionMonitorIdToEdit}
          closeModal={(shouldRefresh) => {
            setConditionMonitorIdToEdit(undefined);
            shouldRefresh && reloadTable();
          }}
        />
      )}

      {deleteConfig && (
        <ConfirmDeleteNotificationModal
          onDelete={() =>
            'selectedIds' in deleteConfig ? deleteConditionMonitors(deleteConfig) : deleteConditionMonitor(deleteConfig)
          }
          onClose={() => setDeleteConfig(undefined)}
          itemCount={'selectedIds' in deleteConfig ? deleteConfig.selectedIds.length : 1}
        />
      )}
    </>
  );
};
