import React from 'react';
import { useTranslation } from 'react-i18next';

export const UnsubscribeDone: React.FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="unsubscribeDone" className="text-center">
      <h2>{t('UNSUBSCRIBE.SUCCESSFUL')}</h2>
      <h4>{t('UNSUBSCRIBE.SUCCESSFUL_MESSAGE')}</h4>
    </div>
  );
};
