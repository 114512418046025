import _ from 'lodash';
import {
  ConditionMonitorInputV1,
  ConditionMonitorNotificationConfigurationInputV1,
  ConditionMonitorOutputV1,
  EmailNotificationRecipientOutputV1,
  NotificationConfigurationOutputV1,
  sqConditionMonitorsApi,
  sqItemsApi,
  sqNotificationConfigurationsApi,
} from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { sqWorkbenchStore, sqWorkstepsStore } from '@/core/core.stores';
import { CapsuleGroupingEnum } from '@/sdk/model/NotificationConfigurationOutputV1';
import { TItemProperties } from '@/tools/itemProperties/hooks/useProperties';
import { TREND_COLUMNS } from '@/trendData/trendData.constants';
import { AxiosResponse } from 'axios';
import { getViewWorksheetLink } from '@/main/routing.utilities';
import { doTrack } from '@/track/track.service';
import { IdentityOption } from '@/core/SelectIdentity.molecule';
import i18next from 'i18next';

export interface ConditionMonitorNotification {
  conditionMonitor: ConditionMonitorOutputV1;
  notificationConfiguration: NotificationConfigurationOutputV1;
  conditionNames: Record<string, string>;
}

export const DEFAULT_CAPSULE_PROPERTIES = [
  _.find(TREND_COLUMNS, { key: 'startTime' })!,
  _.find(TREND_COLUMNS, { key: 'endTime' })!,
  _.find(TREND_COLUMNS, { key: 'duration' })!,
];

export function getDefaultContextUrl(): string {
  return getViewWorksheetLink(
    sqWorkbenchStore.stateParams.workbookId,
    sqWorkbenchStore.stateParams.worksheetId,
    undefined,
    sqWorkstepsStore.current.id,
  );
}

export const newConditionMonitorNotification = (condition: TItemProperties): ConditionMonitorNotification => ({
  conditionMonitor: {
    id: '',
    conditionIds: [condition.id],
    enabled: true,
    name: `${condition.name}`,
    createdAt: '',
    creatorId: '',
    updatedAt: '',
    queryRangeLookAhead: '0',
    type: SeeqNames.Types.ConditionMonitor,
  },
  notificationConfiguration: {
    capsuleProperties: _.map(DEFAULT_CAPSULE_PROPERTIES, (p) => p.propertyName as string),
    capsuleGrouping: CapsuleGroupingEnum.ALL,
    timezone: sqWorkbenchStore.timezone.name,
    contextUrl: getDefaultContextUrl(),
    toEmailRecipients: [
      {
        userId: sqWorkbenchStore.currentUser.id,
        name: sqWorkbenchStore.currentUser.name,
      },
    ],
    ccEmailRecipients: [],
    bccEmailRecipients: [],
  },
  conditionNames: { [condition.id]: condition.name },
});

export async function fetchConditionMonitor(conditionMonitorId: string): Promise<ConditionMonitorNotification> {
  const { data: conditionMonitor } = await sqConditionMonitorsApi.getConditionMonitor({ id: conditionMonitorId });
  const { data: notificationConfiguration } = await sqNotificationConfigurationsApi.getNotificationConfiguration({
    id: conditionMonitorId,
  });
  const conditionNames = await Promise.all(
    _.map(conditionMonitor.conditionIds, (id) => {
      if (conditionMonitor.conditionIds.length > 10) {
        return '';
      } else {
        try {
          return sqItemsApi.getItemAndAllProperties({ id }).then(({ data: { name } }) => name);
        } catch (e) {
          return i18next.t('NOTIFICATIONS.MODAL.CONDITION_NOT_FOUND', { id });
        }
      }
    }),
  );

  return {
    conditionMonitor,
    notificationConfiguration,
    conditionNames: _.zipObject(conditionMonitor.conditionIds, conditionNames),
  };
}

export async function saveConditionMonitorNotificationConfiguration(
  conditionMonitorInput: ConditionMonitorInputV1,
  notificationConfigurationInput: ConditionMonitorNotificationConfigurationInputV1,
  id?: string,
): Promise<AxiosResponse<NotificationConfigurationOutputV1>> {
  const { data: savedConditionMonitor } = id
    ? await sqConditionMonitorsApi.updateConditionMonitor(conditionMonitorInput, { id })
    : await sqConditionMonitorsApi.createConditionMonitor(conditionMonitorInput);
  return sqNotificationConfigurationsApi
    .setNotificationConfigurationForConditionMonitor(notificationConfigurationInput, { id: savedConditionMonitor.id })
    .catch((e) => {
      // If we were creating a new condition monitor, we need to guarantee that the caller can provide the newly
      // created condition monitor id for future calls to this function.
      if (!id) {
        sqConditionMonitorsApi.archiveConditionMonitor({ id: savedConditionMonitor.id });
      }
      throw e;
    });
}

export const recipientsToIdentities = (recipients: EmailNotificationRecipientOutputV1[]): IdentityOption[] =>
  _.map(recipients, (recipient) => ({
    id: recipient.userId!,
    name: recipient.name ?? recipient.emailAddress ?? '',
    email: recipient.emailAddress,
  }));

export const identitiesToRecipients = (identities: IdentityOption[]): EmailNotificationRecipientOutputV1[] =>
  _.map(identities, (identity) =>
    identity.customOption
      ? { name: identity.label!, emailAddress: identity.label! }
      : { userId: identity.id, name: identity.name },
  );

export const recipientsToInput = (recipients: EmailNotificationRecipientOutputV1[]): string[] =>
  _.map(recipients, (recipient) => (recipient.userId ? recipient.userId : recipient.emailAddress!));

export function hasRecipients(notificationConfiguration?: NotificationConfigurationOutputV1): boolean {
  return !_.isEmpty(notificationConfiguration?.toEmailRecipients);
}

const trackEnabled = (enabled: boolean, action: 'Save' | 'Update') => {
  doTrack('Notification', action, enabled ? 'Enabled' : 'Disabled');
};

const trackCapsuleNames = (capsuleProperties: string[], action: 'Save' | 'Update') => {
  const capsuleNames = capsuleProperties.length > 0 ? `properties: ${capsuleProperties.toString()}, ` : '';
  doTrack('Notification', action, `Capsule ${capsuleNames} total: ${capsuleProperties.length}`);
};
const trackEmailToTotal = (toEmailRecipients: EmailNotificationRecipientOutputV1[], action: 'Save' | 'Update') => {
  doTrack('Notification', action, `Email "to" recipients total: ${toEmailRecipients.length}`);
};
const trackEmailCCTotal = (ccEmailRecipients: EmailNotificationRecipientOutputV1[], action: 'Save' | 'Update') => {
  doTrack('Notification', action, `Email "cc" recipients total: ${ccEmailRecipients.length}`);
};
const trackEmailBCCTotal = (bccEmailRecipients: EmailNotificationRecipientOutputV1[], action: 'Save' | 'Update') => {
  doTrack('Notification', action, `Email "bcc" recipients total: ${bccEmailRecipients.length}`);
};
const trackLookAhead = (lookAhead: string, action: 'Save' | 'Update') => {
  doTrack('Notification', action, `Look forward ${lookAhead} seconds`);
};

const trackCapsuleGrouping = (capsuleGrouping: CapsuleGroupingEnum | undefined, action: 'Save' | 'Update') => {
  doTrack('Notification', action, `Capsule grouping: ${capsuleGrouping}`);
};

export function saveNotificationFieldTracking(
  conditionMonitor: ConditionMonitorOutputV1,
  notificationConfiguration: NotificationConfigurationOutputV1,
) {
  trackEnabled(conditionMonitor.enabled, 'Save');
  trackCapsuleNames(notificationConfiguration.capsuleProperties, 'Save');
  trackEmailToTotal(notificationConfiguration.toEmailRecipients, 'Save');
  trackEmailCCTotal(notificationConfiguration.ccEmailRecipients, 'Save');
  trackEmailBCCTotal(notificationConfiguration.bccEmailRecipients, 'Save');
  trackLookAhead(conditionMonitor.queryRangeLookAhead, 'Save');
  trackCapsuleGrouping(notificationConfiguration.capsuleGrouping, 'Save');
}

export function updateNotificationFieldTracking(
  conditionMonitor: ConditionMonitorOutputV1,
  conditionMonitorOriginal: ConditionMonitorOutputV1,
  notificationConfiguration: NotificationConfigurationOutputV1,
  notificationConfigurationOriginal: NotificationConfigurationOutputV1,
) {
  if (conditionMonitor.enabled !== conditionMonitorOriginal.enabled) {
    trackEnabled(conditionMonitor.enabled, 'Update');
  }

  if (conditionMonitor.name !== conditionMonitorOriginal.name) {
    doTrack('Notification', 'Update', 'Name');
  }

  if (notificationConfiguration.capsuleProperties !== notificationConfigurationOriginal.capsuleProperties) {
    trackCapsuleNames(notificationConfiguration.capsuleProperties, 'Update');
  }

  if (notificationConfiguration.contextUrl !== notificationConfigurationOriginal.contextUrl) {
    doTrack('Notification', 'Update', 'Link');
  }

  if (!_.isEqual(notificationConfiguration.toEmailRecipients, notificationConfigurationOriginal.toEmailRecipients)) {
    trackEmailToTotal(notificationConfiguration.toEmailRecipients, 'Update');
  }

  if (
    notificationConfiguration?.ccEmailRecipients.length !== notificationConfigurationOriginal?.ccEmailRecipients.length
  ) {
    trackEmailCCTotal(notificationConfiguration.ccEmailRecipients, 'Update');
  }

  if (!_.isEqual(notificationConfiguration.bccEmailRecipients, notificationConfigurationOriginal.bccEmailRecipients)) {
    trackEmailBCCTotal(notificationConfiguration.bccEmailRecipients, 'Update');
  }

  if (conditionMonitor.queryRangeLookAhead !== conditionMonitorOriginal.queryRangeLookAhead) {
    trackLookAhead(conditionMonitor.queryRangeLookAhead, 'Update');
  }

  if (notificationConfiguration.timezone !== notificationConfigurationOriginal.timezone) {
    doTrack('Notification', 'Update', 'Timezone');
  }

  if (notificationConfiguration.capsuleGrouping !== notificationConfigurationOriginal.capsuleGrouping) {
    trackCapsuleGrouping(notificationConfiguration.capsuleGrouping, 'Update');
  }
}

export interface EmailValidity {
  to: boolean;
  cc: boolean;
  bcc: boolean;
}

function extractEmails(recipients: EmailNotificationRecipientOutputV1[]): string[] {
  return _.chain(recipients)
    .filter((config) => config.emailAddress !== undefined)
    .map((config) => config.emailAddress as string)
    .value();
}

function isValidEmail(email: string): boolean {
  return !!email
    .toLowerCase()
    // Simple match: check for [something]@[something].[something]
    // From
    // https://www.w3resource.com/javascript/form/email-validation.php#:~:text=To%20get%20a%20valid%20email,%5D%2B)*%24%2F
    .match(/^\S+@\S+\.\S+$/);
}

export function validateEmails(
  notificationConfiguration?: Pick<
    NotificationConfigurationOutputV1,
    'toEmailRecipients' | 'ccEmailRecipients' | 'bccEmailRecipients'
  >,
): EmailValidity | undefined {
  if (notificationConfiguration === undefined) {
    return undefined;
  }
  const validator = (emails: string[]) => _.every(emails, (email) => isValidEmail(email));

  return {
    to: validator(extractEmails(notificationConfiguration.toEmailRecipients)),
    cc: validator(extractEmails(notificationConfiguration.ccEmailRecipients)),
    bcc: validator(extractEmails(notificationConfiguration.bccEmailRecipients)),
  };
}

export function allEmailsValid(emailValidity?: EmailValidity): boolean {
  return !!emailValidity && emailValidity.bcc && emailValidity.cc && emailValidity.to;
}
