import { FakeLink } from '@/core/FakeLink';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailRecipientsInput, RecipientsType } from '@/notifications/EmailRecipientsInput';
import { IdentityOption } from '@/core/SelectIdentity.molecule';
import { NotificationConfigurationOutputV1 } from '@/sdk';
import { EmailValidity, recipientsToIdentities, validateEmails } from '@/notifications/notifications.utilities';
import { DEFAULT_NOTIFICATION_CONFIGURATION } from '@/notifications/notifications.constants';
import { Icon } from '@/core/Icon.atom';

interface EmailRecipientsSectionProps {
  notificationConfiguration?: NotificationConfigurationOutputV1;
  onSetIdentities: (type: RecipientsType, identities: IdentityOption[]) => void;
}

export const EmailRecipientsSection: React.FunctionComponent<EmailRecipientsSectionProps> = ({
  notificationConfiguration = DEFAULT_NOTIFICATION_CONFIGURATION,
  onSetIdentities,
}) => {
  const { t } = useTranslation();

  const [showCC, setShowCC] = useState(!_.isEmpty(notificationConfiguration.ccEmailRecipients));
  const [showBCC, setShowBCC] = useState(!_.isEmpty(notificationConfiguration.bccEmailRecipients));
  const [emailValidity, setEmailValidity] = useState<EmailValidity>();

  useEffect(() => {
    if (notificationConfiguration) {
      setEmailValidity(validateEmails(notificationConfiguration));
    }
  }, [notificationConfiguration]);

  return (
    <>
      <div className="flexRowContainer">
        <label className="col-form-label">{t('NOTIFICATIONS.MODAL.EMAIL_INPUT.NOTIFY_FOLLOWING')}</label>
        <EmailRecipientsInput
          type="toEmailRecipients"
          onSetIdentities={(identities) => onSetIdentities('toEmailRecipients', identities)}
          identities={recipientsToIdentities(notificationConfiguration.toEmailRecipients)}
          isInvalid={!emailValidity?.to}
        />
      </div>
      <FakeLink onClick={() => setShowCC(!showCC)} extraClassNames="mt5 displayBlock">
        <Icon icon={showCC ? 'fa-minus' : 'fa-plus'} extraClassNames="mr5" />
        {t('NOTIFICATIONS.MODAL.CC')}
      </FakeLink>
      {showCC && (
        <EmailRecipientsInput
          type="ccEmailRecipients"
          onSetIdentities={(identities) => onSetIdentities('ccEmailRecipients', identities)}
          identities={recipientsToIdentities(notificationConfiguration.ccEmailRecipients)}
          isInvalid={!emailValidity?.cc}
        />
      )}
      <FakeLink onClick={() => setShowBCC(!showBCC)} extraClassNames="mt5 displayBlock">
        <Icon icon={showBCC ? 'fa-minus' : 'fa-plus'} extraClassNames="mr5" />
        {t('NOTIFICATIONS.MODAL.BCC')}
      </FakeLink>
      {showBCC && (
        <EmailRecipientsInput
          type="bccEmailRecipients"
          onSetIdentities={(identities) => onSetIdentities('bccEmailRecipients', identities)}
          identities={recipientsToIdentities(notificationConfiguration.bccEmailRecipients)}
          isInvalid={!emailValidity?.bcc}
        />
      )}
    </>
  );
};
