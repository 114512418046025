import React, { useState } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/core/Checkbox.atom';
import { DURATION_TIME_UNITS, KEY_CODES } from '@/main/app.constants';
import { ValueWithUnits, ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';
import { AdvancedSection } from '@/core/AdvancedParameters.atom';
import TimeZoneSelector from '@/core/TimeZoneSelector.molecule';
import { Collapse } from 'react-bootstrap';
import { FormInput } from '@/core/FormInput.atom';
import { GetLink } from '@/core/GetLink.atom';
import { TranslationWithHTML } from '@/core/ContainerWithHTML.atom';
import { ColumnIF, CustomPropertySelector, SuggestedPropertiesMode } from '@/utilities/CustomPropertySelector.atom';
import { Icon } from '@/core/Icon.atom';
import { ConditionMonitorOutputV1, NotificationConfigurationOutputV1 } from '@/sdk';
import { CapsuleGroupingEnum } from '@/sdk/model/NotificationConfigurationOutputV1';
import {
  DEFAULT_CAPSULE_PROPERTIES,
  getDefaultContextUrl,
  identitiesToRecipients,
} from '@/notifications/notifications.utilities';
import { getValueAndUnits, updateUnits } from '@/datetime/dateTime.utilities';
import { sqTimezones } from '@/utilities/datetime.constants';
import { getConditionMonitorNotificationsScheduleDescription } from '@/services/systemConfiguration.utilities';
import classNames from 'classnames';
import { ToggleSwitch } from '@/core/ToggleSwitch.atom';
import { EmailRecipientsSection } from '@/notifications/EmailRecipientsSection';
import { PropertyColumn } from '@/trendData/trendData.constants';
import { TextButton } from '@/core/TextButton.atom';
import { NotificationEditingContext } from '@/notifications/notifications.constants';

export interface NotificationsDetailsProps {
  conditionMonitor: ConditionMonitorOutputV1;
  notificationConfiguration: NotificationConfigurationOutputV1;
  conditionNames: Record<string, string>;
  setConditionMonitor: (conditionMonitor: ConditionMonitorOutputV1) => void;
  setNotificationConfiguration: (notificationConfiguration: NotificationConfigurationOutputV1) => void;
  context: NotificationEditingContext;
}

export const NotificationsDetails: React.FunctionComponent<NotificationsDetailsProps> = ({
  conditionMonitor,
  notificationConfiguration,
  conditionNames,
  setConditionMonitor,
  setNotificationConfiguration,
  context,
}) => {
  const { t } = useTranslation();

  const lookAhead =
    conditionMonitor.queryRangeLookAhead !== '0'
      ? getValueAndUnits(moment.duration(conditionMonitor.queryRangeLookAhead ?? 0, 'seconds'))
      : { value: 0, units: DURATION_TIME_UNITS[3].unit[0], translationKey: 'UNITS.DAYS' };

  const timezone =
    _.find(sqTimezones.timezones, { name: notificationConfiguration.timezone }) ?? sqTimezones.defaultTimezone;
  const schedule = _.isEmpty(conditionMonitor.cronSchedule)
    ? getConditionMonitorNotificationsScheduleDescription()
    : conditionMonitor.cronScheduleDescription;

  const [advancedSectionExpanded, setAdvancedSectionExpanded] = useState(false);

  const setLookAheadAndConditionMonitor = (duration: ValueWithUnitsItem) => {
    const { value } = updateUnits(duration.value, 'seconds', duration.units);
    setConditionMonitor({ ...conditionMonitor, queryRangeLookAhead: value.toString() });
  };

  const isChecked = (capsuleProperty: PropertyColumn) =>
    _.includes(notificationConfiguration.capsuleProperties, capsuleProperty.propertyName);

  const removeFromProperties = (capsuleProperty: PropertyColumn): string[] =>
    _.without(notificationConfiguration.capsuleProperties, capsuleProperty.propertyName) as string[];

  const addToProperties = (capsuleProperty: PropertyColumn): string[] =>
    _.concat(notificationConfiguration.capsuleProperties, capsuleProperty.propertyName) as string[];

  const defaultCheckboxList = () =>
    _.map(DEFAULT_CAPSULE_PROPERTIES, (capsuleProperty) => (
      <Checkbox
        classes="textPrimaryMenuItem"
        id={`${capsuleProperty.propertyName}-notifications-checkbox`}
        key={capsuleProperty.key}
        label={capsuleProperty.title!}
        isChecked={isChecked(capsuleProperty)}
        onChange={() => {
          setNotificationConfiguration({
            ...notificationConfiguration,
            capsuleProperties: isChecked(capsuleProperty)
              ? removeFromProperties(capsuleProperty)
              : addToProperties(capsuleProperty),
          });
        }}
        skipMemo={true}
      />
    ));

  const getCustomProperties = (capsuleProperties: string[]) => {
    return _.reduce(
      capsuleProperties,
      (accum: string[], current) => {
        const isDefaultProperty = DEFAULT_CAPSULE_PROPERTIES.findIndex(
          (defaultProperty) => defaultProperty.propertyName === current,
        );
        if (!~isDefaultProperty) {
          accum.push(current);
        }
        return accum;
      },
      [],
    );
  };

  const customCheckboxList = (capsuleProperties: string[]) =>
    _.map(getCustomProperties(capsuleProperties), (capsuleProperty) => (
      <Checkbox
        classes="textPrimaryMenuItem"
        id={`${capsuleProperty}-notifications-checkbox`}
        key={`${capsuleProperty}-checkbox`}
        label={capsuleProperty}
        isChecked={true}
        onChange={() => {
          setNotificationConfiguration({
            ...notificationConfiguration,
            capsuleProperties: _.without(notificationConfiguration.capsuleProperties, capsuleProperty),
          });
        }}
        skipMemo={true}
      />
    ));

  const topSection = (
    <div className="flexColumnContainer flexSpaceBetween">
      <div className="text-italic mr50">
        <TranslationWithHTML
          translationKey={
            conditionMonitor.conditionIds.length > 10
              ? 'NOTIFICATIONS.MODAL.SEEQ_WILL_CHECK_MANY'
              : 'NOTIFICATIONS.MODAL.SEEQ_WILL_CHECK'
          }
          translationParams={{
            icon: "<i class='fc sq-icon-color fc-capsule-set'></i>",
            name: `<strong>${
              conditionMonitor.conditionIds.length > 10
                ? conditionMonitor.conditionIds.length
                : _.map(conditionMonitor.conditionIds, (id) => conditionNames[id]).join(', ')
            }</strong>`,
            schedule: `${schedule}`,
          }}
        />
      </div>
      <ToggleSwitch
        testId="notificationEnabled"
        isOn={conditionMonitor.enabled}
        onChange={() => setConditionMonitor({ ...conditionMonitor, enabled: !conditionMonitor.enabled })}
        formattedLabel={<span className="semi-bold">{t('NOTIFICATIONS.MODAL.ENABLED')}</span>}
      />
    </div>
  );

  const nameSection = (
    <div className="mb7">
      <label className="col-form-label">{t('NOTIFICATIONS.MODAL.NOTIFICATION_NAME')}</label>
      <Icon
        icon="fa-info-circle"
        testId="title-tooltip"
        extraClassNames="ml5"
        tooltip={t('NOTIFICATIONS.MODAL.TOOLTIP.EMAIL_SUBJECT', { name: conditionMonitor.name })}
        tooltipPlacement="right"
      />
      <FormInput
        value={conditionMonitor.name}
        onChange={(event) => {
          setConditionMonitor({ ...conditionMonitor, name: event.currentTarget.value });
        }}
        placeholder={t('NOTIFICATIONS.MODAL.NOTIFICATION_NAME_PLACEHOLDER')}
        data-testid="nameInput"
      />
    </div>
  );

  const checkboxSection = (
    <div className="mb7">
      <label>{t('NOTIFICATIONS.MODAL.INCLUDE_CAPSULE_PROPERTIES')}</label>
      <div>
        {defaultCheckboxList()}
        {customCheckboxList(notificationConfiguration.capsuleProperties)}
      </div>
      <div className="width-250">
        <CustomPropertySelector
          itemIds={conditionMonitor.conditionIds ?? []}
          suggestedPropertiesMode={SuggestedPropertiesMode.Capsules}
          addPropertyColumn={(capsuleProperty: ColumnIF) => {
            setNotificationConfiguration({
              ...notificationConfiguration,
              capsuleProperties: _.uniq(
                notificationConfiguration.capsuleProperties.concat(capsuleProperty.propertyName),
              ),
            });
          }}
          dropdownPlaceholder="CUSTOM_COLUMN_SELECTOR.CUSTOM_PROPERTY"
        />
      </div>
    </div>
  );

  const includeLinkSection = !notificationConfiguration.contextUrl ? null : (
    <div className="mb7">
      <GetLink
        key="includeLinkSection"
        testId="includeLinkSection"
        title="NOTIFICATIONS.MODAL.INCLUDE_LINK"
        value={notificationConfiguration.contextUrl ?? ''}
        tooltip={t('NOTIFICATIONS.MODAL.TOOLTIP.INCLUDE_LINK')}
        readOnly={false}
        extraButtons={
          context === NotificationEditingContext.Properties
            ? [
                <TextButton
                  label=""
                  icon="fc-link-seeq"
                  iconStyle="theme"
                  key=""
                  tooltip="NOTIFICATIONS.MODAL.DEFAULT_LINK"
                  tooltipOptions={{ placement: 'top' }}
                  onClick={() => {
                    setNotificationConfiguration({
                      ...notificationConfiguration,
                      contextUrl: getDefaultContextUrl(),
                    });
                  }}
                />,
              ]
            : []
        }
        onChange={(event) => {
          setNotificationConfiguration({
            ...notificationConfiguration,
            contextUrl: event.currentTarget.value,
          });
        }}
      />
    </div>
  );

  const advancedSectionContents = (
    <div id="advancedScheduleOptions" className="ml20 mr10">
      <div id="lookAhead" className="mb7">
        <label>{t('NOTIFICATIONS.MODAL.LOOK_AHEAD.LABEL')}</label>
        <div className="text-italic mb7" data-testId="lookAheadHelp">
          {t('NOTIFICATIONS.MODAL.LOOK_AHEAD.HELP')}
        </div>
        <ValueWithUnits
          propName="lookAhead"
          onChange={setLookAheadAndConditionMonitor}
          insideModal={true}
          min={0}
          availableUnits={DURATION_TIME_UNITS}
          required={false}
          defaultValue={lookAhead}
        />
      </div>

      {/* Timezone */}
      <div className={'mb7'}>
        <label>{t('NOTIFICATIONS.MODAL.DEFINE_TIMEZONE.LABEL')}</label>
        <Icon
          icon="fa-info-circle"
          testId="title-tooltip"
          extraClassNames="ml5"
          tooltip={t('NOTIFICATIONS.MODAL.TOOLTIP.TIMEZONE')}
        />
        <TimeZoneSelector
          extraClassNames="width-220"
          timezone={timezone}
          onSelect={(timezone) => {
            setNotificationConfiguration({
              ...notificationConfiguration,
              timezone: timezone.name,
            });
          }}
        />
      </div>

      <Checkbox
        id="capsuleGrouping"
        classes={classNames('cursorPointer m5')}
        value={CapsuleGroupingEnum.ALL}
        label="NOTIFICATIONS.MODAL.EMAIL_OPTIONS.GROUP_INTO_ONE"
        isChecked={notificationConfiguration.capsuleGrouping === CapsuleGroupingEnum.ALL}
        skipMemo={true}
        onClick={() => {
          setNotificationConfiguration({
            ...notificationConfiguration,
            capsuleGrouping:
              notificationConfiguration.capsuleGrouping === CapsuleGroupingEnum.ALL
                ? CapsuleGroupingEnum.CAPSULE
                : CapsuleGroupingEnum.ALL,
          });
        }}
      />
    </div>
  );
  const advancedSection = (
    <div className="form-group mb0">
      <div
        className="flexColumnContainer flexSpaceBetween mt5 mb10 cursorPointer noOutline"
        tabIndex={0}
        data-testid="notificationAdvanceSectionButton"
        onClick={() => setAdvancedSectionExpanded(!advancedSectionExpanded)}
        onKeyUp={(e) => e.keyCode === KEY_CODES.ENTER && setAdvancedSectionExpanded(!advancedSectionExpanded)}>
        <AdvancedSection collapsed={!advancedSectionExpanded} t={t} alternateName={'NOTIFICATIONS.MODAL.ADVANCED'} />
      </div>
      <Collapse in={advancedSectionExpanded} unmountOnExit={true}>
        {advancedSectionContents}
      </Collapse>
    </div>
  );

  return (
    <>
      {topSection}
      {nameSection}
      {checkboxSection}
      {includeLinkSection}
      <EmailRecipientsSection
        notificationConfiguration={notificationConfiguration}
        onSetIdentities={(type, identities) => {
          setNotificationConfiguration({
            ...notificationConfiguration,
            [type]: identitiesToRecipients(identities),
          });
        }}
      />
      <hr className="mt10 mb10" />
      {advancedSection}
    </>
  );
};
