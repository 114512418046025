import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { sqWorkbenchStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { isCsrfSet } from '@/utilities/auth.utilities';
import { setCurrentUser } from '@/workbench/workbench.actions';
import { UnsubscribeDone } from '@/notifications/UnsubscribeDone.molecule';
import { ConfirmUnsubscribeLoggedIn } from '@/notifications/ConfirmUnsubscribeLoggedIn.molecule';
import { ConfirmUnsubscribeNotLoggedIn } from '@/notifications/ConfirmUnsubscribeNotLoggedIn.molecule';
import _ from 'lodash';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { sqNotificationConfigurationsApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { errorToast } from '@/utilities/toast.utilities';

export const Unsubscribe: React.FunctionComponent = () => {
  const userEmail = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser.email);
  const { notificationId } = useParams();
  const [searchParams] = useSearchParams();
  const name = searchParams.get(SeeqNames.API.QueryParams.Name) ?? '';
  const creator = searchParams.get(SeeqNames.API.QueryParams.Creator) ?? '';
  const admin = searchParams.get(SeeqNames.API.QueryParams.Admin) ?? '';

  const [isLoggedIn, setLoggedIn] = useState<boolean>();
  const [unsubscribedSuccessful, setUnsubscribedSuccessful] = useState(false);

  const handleUnsubscribeClick = async () => {
    if (!_.isNil(notificationId)) {
      try {
        await sqNotificationConfigurationsApi.unsubscribeFromItem({ id: notificationId });
        setUnsubscribedSuccessful(true);
      } catch (error) {
        errorToast({ httpResponseOrError: error, displayForbidden: true });
      }
    }
  };

  useEffect(() => {
    if (isCsrfSet()) {
      if (userEmail) {
        return setLoggedIn(true);
      }

      // To get the authenticated user email (since this page exists outside 'WorkbenchWrapperPage')
      setCurrentUser()
        .then(() => setLoggedIn(true))
        .catch(() => setLoggedIn(false));
    } else {
      setLoggedIn(false);
    }
  }, []);

  const renderMainContent = () => {
    if (_.isNil(isLoggedIn)) {
      return <IconWithSpinner testId="loading" spinning={true} />;
    }

    if (!isLoggedIn) {
      return <ConfirmUnsubscribeNotLoggedIn creator={creator} admin={admin} />;
    }

    if (!unsubscribedSuccessful) {
      return (
        <ConfirmUnsubscribeLoggedIn
          creator={creator}
          admin={admin}
          notificationName={name}
          userEmail={userEmail}
          handleUnsubscribeClick={handleUnsubscribeClick}
        />
      );
    }

    return <UnsubscribeDone />;
  };

  return (
    <div data-testid="unsubscribePage" className="flexColumnContainer flexCenter splashScreen fullViewport">
      <div className="flexCenter jumbotron">
        <div data-testid="mainContent" className="text-center">
          <img src="/img/Seeq_logo_darkBlue_sm.png" />
          <hr className=" width-100percent" />
          {renderMainContent()}
        </div>
      </div>
    </div>
  );
};
